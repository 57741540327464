import * as React from "react"
import LayoutSENR from "../components/layout/layout-senr"
import { useTranslation } from "gatsby-plugin-react-i18next";
import FullTitleGenericComponent from "../components/shared/full-title-generic";
import { graphql } from 'gatsby'
import SEOComponent from "../components/seo";
import parse from "../utils/html-parser.options"
import videojs from 'video.js';
import "../styles/article.css"
import "../styles/rte.css"

const SustainableWeekPage = ({ data }) => {
  const { t } = useTranslation();
  const siteContent = data.siteContent
  const numericWeekContent = data.numericWeekContent
  React.useEffect(() => {

    // get videojs from document
    const video = document.getElementsByClassName("video-js")[0];
    if(video == null) {
        console.log("no video found");
        return;
    }
    var options = {
        controlBar: {
            children: [
              'PlayToggle',
              'ChaptersButton',
              //'VolumeMenuButton',
              'ProgressControl',
              //'CurrentTimeDisplay',
              //'TimeDivider',
              //'LiveDisplay',
              //'RemainingTimeDisplay',
              
              
              'CaptionsButton',
              'volumePanel',
              'DescriptionsButton',
              //'SubtitlesButton',
              'qualitySelector',
              'fullscreenToggle',
            ],
        },
    };
    // init videojs
    videojs(video, options, function () {
        videojs.log('player is ready');
    });
  }, [numericWeekContent]);
  return (
    <main>
      <LayoutSENR siteContent={siteContent}>
        <SEOComponent title={numericWeekContent.title} description={numericWeekContent.title} article />
        <FullTitleGenericComponent headerImage={numericWeekContent.image} title={numericWeekContent.title} ></FullTitleGenericComponent>
        <section className="section mt-10 px-5">
          <div className="whitespace-normal rte">
              {parse(numericWeekContent.description ?? "")}
          </div>
        </section>
      </LayoutSENR>
    </main>
  )
}
export default SustainableWeekPage;


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter:{language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
          
        }
      }
    }
    siteContent(language: {eq: $language}) {
          editionYear
          programYear
          edition {
            description
          }
          partner {
            description
          }
          language
          linkPass
          cookieURL
        personalDataURL
        mediaKit
        banner
    }
    numericWeekContent(language: {eq: $language}) {
        title
        image
        description
    }
  }
`
